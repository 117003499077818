import { Environment } from '../../../environment.interface';

export const environment: Environment = {
  production: true,
  authApi: 'ozd',
  scope: 'admin',
  platform: 'web',

  appVersion: '1.1.3',

  apiBaseUrl: 'https://api.expertea.se/ee/api',
  imageBaseUrl: 'https://api.expertea.se/ee/api/v2/public/file',
  socketIoUrl: 'https://api.expertea.se',

  apiPageSize: 10,

  jwt: { refreshTokenNonce: 'A2UFZ8Jjxp' },

  publicUrl: 'adm.expertea.se',
};
